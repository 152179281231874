//store.commit("SET_SPARE_PARTS", [{}]);
const mutations = {
  SET_NAVBAR_ACTIVE_NAME: (state, name) => {
    state.navbarActiveName = name;
  },
  SET_TOKEN: (state, name) => {
    state.token = name;
  },
  SET_NAME: (state, name) => {
    state.username = name;
    console.log(state.username)
    sessionStorage.setItem("username", state.username)
  },
  SET_AVATAR: (state, name) => {
    state.photo = name;
    sessionStorage.setItem("photo", state.photo)
  }
}
export default mutations;
