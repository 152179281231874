import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)
const routes = [
  {
    path: "",
    redirect: "/login",
    hidden: true
  },
  {
    path: "/login",
    name: "Login",
    hidden: true,
    meta: {
      title: "登录"
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue")
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "注册"
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/register.vue")
  },
  //数据总览
  {
        path: "/index",
        name: "Index",
        redirect: "/index/managewww",
        meta: {
          title: "首页",
          icon: "el-icon-pie-chart",
          breadcrumb: true,
          belongNavbar: "BurseManage"
        },
        component: () => import(/* webpackChunkName: "burse-manage" */ "../views/layout/index.vue"),
        children: [
		      {
		        path: "managewww",
		        name: "managewww",
		        meta: {
		          title: "数据总览",
		          icon: "el-icon-bank-card",
		          breadcrumb: true,
		        },
		        component: () => import( /* webpackChunkName: "burse-manage" */ "../views/index.vue" )
		    }]
  },
  //管理首页
  {
    path: "/oilCard-manage",
    redirect: "/oilCard-manage",
    name: "OilCardManage",
    meta: {
      title: "管理中心",
      icon: "el-icon-user",
      breadcrumb: true,
      belongNavbar: "BurseManage"
    },
    component: () => import(/* webpackChunkName: "layout" */ "../views/layout/index.vue"),
    children: [
      {
        path: "manage",
        name: "manage",
        meta: {
          title: "油卡管理",
          icon: "el-icon-bank-card",
          breadcrumb: true,
        },
        component: () => import( /* webpackChunkName: "burse-manage" */ "../views/oil-manage/manage.vue" )
      },
      {
	      path: "manageDetail",
        name: "manageDetail",
        hidden:true,
        meta: {
          title: "油卡管理详情",
          icon: "el-icon-bank-card",
          breadcrumb: true,
          //selfbreadcrumb: true,
        },
        component: () =>
        import( /* webpackChunkName: "burse-manage" */ "../views/oil-manage/manageDetail.vue" )
			},  
			
			{
        path: "profitManagement",
        name: "profitManagement",
        meta: {
          title: "分润管理",
          icon: "el-icon-bank-card",
          breadcrumb: true,
        },
        component: () => import( /* webpackChunkName: "burse-manage" */ "../views/profitMananger/profitMananger.vue" )
      },
			
      {
        path: "oilStationmanange",
        name: "oilStationmanange",
        meta: {
        	title: "油站管理",
        	icon: "el-icon-postcard",
          breadcrumb: true,
          //selfbreadcrumb: true,
        },
        component: () =>
          import(/* webpackChunkName: "burse-manage" */ "../views/oilstation-manange/flowDetail.vue")
      },
      {
        path: "oilStationDetail",
        name: "oilStationDetail",
        hidden:true,
        meta: {
        	title: "油站管理详情",
        	icon: "el-icon-postcard",
          breadcrumb: true,
          //selfbreadcrumb: true,
        },
        component: () =>
          import(/* webpackChunkName: "burse-manage" */ "../views/oilstation-manange/flowRecordDetail.vue")
      },
      {
        path: "addOildetail",
        name: "addOildetail",
        meta: {
        	title: "加油订单",
        	icon: "el-icon-copy-document",
          breadcrumb: true,
         // selfbreadcrumb: true,
        },
        component: () =>
          import(/* webpackChunkName: "burse-manage" */ "../views/addoil-detail/applyWithdraw.vue")
      },
      {
        path: "withdrawdetail",
        name: " ",
        meta: {
          title: "提现管理",
          icon: "el-icon-coin",
          breadcrumb: true,
         // selfbreadcrumb: true,
        },
        component: () =>
          import(
              /* webpackChunkName: "burse-manage" */ "../views/Withdrawal-record/withdrawDetail.vue"
          )
      },
      {
        path: "rechargedetail",
        name: "rechargeDetail",
        meta: {
        	title: "转卡记录",
          icon: "el-icon-tickets",
          breadcrumb: true,
         // selfbreadcrumb: true,
        },
        component: () =>
          import(/* webpackChunkName: "burse-manage" */ "../views/Card-record/transferDetail.vue")
      },
      {
        path: "transferdetail",
        name: "transferDetail",
        meta: {
         	title: "充值记录",
         	icon: "el-icon-files",
          breadcrumb: true,
//        selfbreadcrumb: true,
        },
        component: () =>
          import(/* webpackChunkName: "burse-manage" */ "../views/recharge-record/rechargeDetail.vue")
      },
      {
        path: "invoicemanange",
        name: "invoicemanange",
        meta: {
        	title: "发票管理",
        	icon: "el-icon-document-copy",
          breadcrumb: true,
//        selfbreadcrumb: true,
        },
        component: () =>
          import(/* webpackChunkName: "burse-manage" */ "../views/invoice-manage/invoicebill.vue" )
      },
      {
        path: "invoicemanangeDetail",
        name: "invoicemanangeDetail",
        hidden:true,
        meta: {
        	title: "发票管理详情",
        	icon: "el-icon-document-copy",
          breadcrumb: true,
          //selfbreadcrumb: true,
        },
        component: () =>
          import(/* webpackChunkName: "burse-manage" */ "../views/invoice-manage/invoicebillDetail.vue")
      }

    ]
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
export default router;
